<template>
    <bread-page>
        <div class="main">
            <div class="sub-title">
                <span>专利公开详情</span>
            </div>
            <div class="content">
                <div class="tit">
                    <p></p>
                    <span>著录项</span>
                </div>
                <div class="box">
                    <div class="con">
                        <div v-for="(item, index) in listDetail" :key="index + 2">
                            <div class="con-text">
                                <div class="text">{{ item.name }}</div>
                                <div class="text2">{{ item.value }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="con">
                        <div v-for="(item, index) in listmine" :key="index + 4">
                            <div class="con-text">
                                <div class="text">{{ item.name }}</div>
                                <div class="text2">{{ item.value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(item, index) in list" :key="index">
                    <div class="tit">
                        <p></p>
                        <span>{{ item.lable }}</span>
                    </div>
                    <div class="box2">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </bread-page>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
export default {
    components: { BreadPage },
    data() {
        return {
            list: [
                {
                    lable: '摘要',
                    title: '本发明公开了-种基 于稀疏谱恢复的机载=维异构阵杂波抑制方法,包括步骤:建立机载三维异构阵的几何模型,并获取机载=维异构阵接收的杂波模型;建立对应的完备字典矩阵;通过压缩感知算法进行杂波谱恢复,再引入联合稀疏模型,对恢复的杂波空时谱进行优化;通过协方差矩阵和杂波分布特性间的等价关系,估计协方差矩阵,进而构造相应的自适应滤波器。本发明将稀疏恢复模型引入到机载三维异构阵的杂波抑制,在正确构建压缩感知模型后,稀疏恢复的过程则是单纯的角度、多普勒求解问题,并不会受到杂波的空变影响,十分契合机载三维异构阵的杂波抑制。'
                },
                {
                    lable: '权利要求书',
                    title: '基于稀疏谱恢复的机载E维异构阵杂波抑制方法,其特征在于,包括以下步骤:步骤1 ,建立机载三维异构阵的几何模型,并获取机载三维异构阵接收的杂波模型;步骤2 ,根据机载三维异构阵接收的杂波模型,建立对应的完备字典矩阵;通过压缩感知算法进行杂波谱恢复,得到对应的杂波空时谱;再引入联合稀疏模型,对恢复的杂波空时谱进行优化, 得到对应的优化后的高分辨杂波空时谱;步骤3 ,在获得优化后的高分辨杂波空时谱的基础上,通过协方差矩阵和杂波分布特性间的等价关系,估计协方差矩阵,进而构造相应的自适应滤波器,对机载三维异构阵接收的杂波进行有效抑制。'
                },
                {
                    lable: '说明书'
                }
            ],
            listDetail: [
                {
                    name: '申请号:',
                    value: 'CN202011599496.0'
                },
                {
                    name: '公开号:',
                    value: 'CN112800497A'
                },
                {
                    name: '代理机构:',
                    value: '西安睿通知识产权代理实务所…'
                },
                {
                    name: '主分类号:',
                    value: 'G06F30/10'
                },
                {
                    name: '地址:',
                    value: '710071陕西省西安市雁塔区太白南路'
                },
                {
                    name: '代理机构:',
                    value: '西安睿通知识产权代理事务所'
                }
            ],
            listmine: [
                {
                    name: '申请日:',
                    value: '2020-12-20'
                },
                {
                    name: '公开日:',
                    value: '2020-12-20'
                },
                {
                    name: '发明人:',
                    value: '梁毅；殷志文；吴建新；王文杰；'
                },
                {
                    name: '分类号:',
                    value: 'G06F30/10 ; G06F30/27 ; G06F17/16; G06F17/11 ;G01S7/02 ;G01S7/41 ;'
                },
                {
                    name: '国省代码:',
                    value: '中国'
                },
                {
                    name: '代理人:',
                    value: '惠文轩'
                }
            ]
        };
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    margin-bottom: 106px;
    .top {
        .flex();
        padding: 0 150px 0;
        span {
            width: 46px;
            font-size: 16px;
            color: #fff;
            background: #ff8700;
            border-radius: 2px;
            text-align: center;
            line-height: 26px;
        }
        .title {
            font-size: 24px;
            font-weight: bold;
            color: #00684f;
            line-height: 34px;
            text-align: center;
            padding: 30px 0;
            border-bottom: 1px solid @bg;
            margin: 0 8px;
        }
        p {
            width: 46px;
            line-height: 26px;
            background: #6060b3;
            font-size: 16px;
            color: #fff;
            text-align: center;
            border-radius: 2px;
        }
    }
}
.sub-title {
    span {
        .flex();
        justify-content: center;
        padding-top: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #00684f;
        line-height: 22px;
    }
}

.content {
    padding: 0 30px 50px;
    .box2 {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin-top: 16px;
    }
    .box {
        .flex();
        justify-content: space-between;
        margin-right: 76px;
        .con-text {
            margin-bottom: 10px;
            .flex();
            .text {
                width: 60px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 32px;
            }
            .text2 {
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                line-height: 22px;
                margin-left: 34px;
            }
        }
    }
    .tit {
        .flex();
        margin: 50px 0 16px;
        p {
            width: 5px;
            height: 16px;
            background: #00684f;
            border-radius: 3px;
            margin-right: 8px;
        }
        span {
            font-size: 16px;
            font-weight: bold;
            color: #00684f;
            line-height: 22px;
        }
    }
}
</style>
